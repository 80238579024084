import React, { useEffect, useState } from 'react';
import { DahboardMenu } from '../DahboardMenu';
import { TopHeader } from '../TopHeader';
import { useNavigate } from 'react-router';
import { getBooksListAction, getStaffUpdatedAction } from '../../../Store/Action';
import { useDispatch, useSelector } from 'react-redux';
import DeleteItemPopup from '../../Popups/DeleteItemPopup';
import { toast } from 'react-toastify';
import { setBooksListStore, setEditOptionData } from '../../../Store/Reducer';
import { getBookListURL, updateBookDetailsURL } from '../../../Api/ContantUrls';
import { FilterbyFiledAsending, FilterbyFiledDsending, FilterbyStringAsending, FilterbyStringDsending } from '../../../util/helper';
import { buttonCSS } from '../../../util/contant';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ThreeDots } from 'react-loader-spinner';


const BooksList = () => {
    const booksListStore = useSelector(state => state?.booksListStore);
    const [booksList, setBooksList] = useState([]);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [deleteData, setDeleteData] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const [isUpdate, setIsUpdate] = useState(false);
    const [orginalData, setOriginalData] = useState([]);
    const [callOnce, setCallOnce] = useState(false);

    const [bookIdFilter, setBookIdFilter] = useState(false);
    const [bookNameFilter, setBookNameFilter] = useState(false);
    const [bookDescFilter, setBookDescFilter] = useState(false);


    const [hasMore, setHasMore] = useState(true)
    const [pageCount, setPageCount] = useState(20)
    const [pageNumber, setPageNumber] = useState(1)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const editDataIds = [];

    const FilterbyTitleOrder = (a, b) => {
        if (a.bookName.length !== 0 && a.bookName[0].value && b.bookName.length !== 0 && b.bookName[0].value) {
            if (a.bookName[0].value.toLowerCase() < b.bookName[0].value.toLowerCase()) {
                return -1;
            }
            if (a.bookName[0].value.toLowerCase() > b.bookName[0].value.toLowerCase()) {
                return 1;
            }
        }
        return 0;
    }

    const FilterbyBookId = (a, b) => {
        return a.bookId - b.bookId;
    }

    useEffect(() => {
        if (booksListStore.length === 0 && booksList.length === 0) {
            getStaffs();
        }
        if (booksListStore.length !== 0 && booksList.length === 0) {
            setBooksList(booksListStore.slice().sort(FilterbyBookId));
            setOriginalData(booksListStore.slice().sort(FilterbyBookId))
            if (booksListStore.length !== 0 && !callOnce) {
                booksListStore.slice().sort(FilterbyTitleOrder).map((val) => {
                    editDataIds.push(val._id)
                })
                dispatch(setEditOptionData(editDataIds))
                setCallOnce(!callOnce);
                if (booksListStore.length < pageCount) {
                    setHasMore(false)
                }
            }
        }

    }, [booksListStore, isUpdate])
    useEffect(() => () => {
        dispatch(setBooksListStore([]))
    }, []);

    const openDelete = (data, id, index) => {
        setDeleteData(data);
        setSelectedId(id);
        setSelectedIndex(index)
        setOpenDeletePopup(true);
    }

    const closeDeletePopup = () => {
        setOpenDeletePopup(false);
    }

    const getStaffs = async () => {
        const reqObj = {
            "getLatestFirst": true,
            "pageNumber": pageNumber,
            "numberOfRecordsPerPage": pageCount,
            "filter": {
                "active": true
            }
        }
        dispatch(await getBooksListAction(reqObj));
    }

    const deleteGift = async () => {
        const reqObj = {
            "bookId": selectedId,
            "dataToUpdate": {
                "active": false
            }
        }
        const response = await getStaffUpdatedAction(dispatch, updateBookDetailsURL, reqObj);
        if (response?.status === 200 && response?.data) {
            toast.success("Success")
            const existObj = JSON.parse(JSON.stringify(booksList));
            existObj.splice(selectedIndex, 1);
            setBooksList(existObj);
            dispatch(setBooksListStore(existObj))
            setIsUpdate(!isUpdate)
        } else {
            toast.error("Failed")
        }
    }

    const filterData = (val, name) => {
        let check = val;
        let filterData = [];
        if (name === 'bookId') {
            setBookIdFilter(!bookIdFilter);
            filterData = check ? FilterbyFiledAsending(orginalData, name) : FilterbyFiledDsending(orginalData, name);
        }
        if (name === 'bookName') {
            setBookNameFilter(!bookNameFilter);
            filterData = check ? FilterbyStringAsending(orginalData, name) : FilterbyStringDsending(orginalData, name);
        }
        if (name === 'bookDescription') {
            setBookDescFilter(!bookDescFilter);
            filterData = check ? FilterbyFiledAsending(orginalData, name) : FilterbyFiledDsending(orginalData, name);
        }
        filterData.map((val) => {
            editDataIds.push(val?.mobile)
        })
        dispatch(setEditOptionData(editDataIds))
        setBooksList(filterData);
        setIsUpdate(!isUpdate);
    }

    const fetchMoreData = () => {
        setTimeout(async () => {
            const reqObj = {
                "getLatestFirst": true,
                "pageNumber": pageNumber + 1,
                "numberOfRecordsPerPage": pageCount,
                "filter": {
                    "active": true
                }
            }

            const response = await getStaffUpdatedAction(dispatch, getBookListURL, reqObj, false);
            if (response?.status === 200 && response?.data) {
                setPageNumber(pageNumber + 1)
                const { payload } = response?.data;
                if (payload?.bookList.length !== 0) {
                    const listData = booksList.length === 0 ? booksListStore : booksList;
                    const bookDatas = [...listData, ...payload?.bookList]
                    setBooksList(bookDatas.slice().sort(FilterbyBookId))

                    bookDatas.slice().sort(FilterbyBookId).map(val => {
                        editDataIds.push(val._id)
                    })
                    dispatch(setEditOptionData(editDataIds))

                    if (payload?.bookList.length < pageCount) {
                        setHasMore(false)
                        dispatch(setBooksListStore(bookDatas.slice().sort(FilterbyBookId)));
                    }
                } else {
                    setHasMore(false)
                }

            } else {
                setBooksList(booksList);
                setHasMore(false);
            }
            setIsUpdate(!isUpdate)
        }, 500);
    }

    return (
        <React.Fragment>
            <TopHeader />
            <DahboardMenu />
            {openDeletePopup && <DeleteItemPopup deleteData={deleteGift} closeModal={closeDeletePopup} modalIsOpen={openDeletePopup} isFrom="GameBook" data={deleteData} />}

            <div className="mt-10 h-full" >
                <div className="
max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
xl:max-w-full xl:pl-24  xl:ml-40 xl:px-12 2xl:max-w-[1400px] mx-auto
flex">
                    <div className="flex flex-col h-full">
                        <div className="relative sm:rounded-lg">
                            <div className="flex justify-end pb-3 m-2">
                                <div id="sticky-banner" tabindex="-1" >
                                    <div class="">
                                        <button className={buttonCSS.addbtn} onClick={() => navigate('/dashboard/books')}>
                                            <svg className="h-6 w-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                            </svg>
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-wrp block">
                                <InfiniteScroll
                                    dataLength={booksList.length}
                                    next={fetchMoreData}
                                    loader={booksList.length !== 0 && <ThreeDots
                                        visible={true}
                                        height="80"
                                        width="80"
                                        color="#4fa94d"
                                        radius="9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />}
                                    hasMore={hasMore}
                                    endMessage={
                                        <p style={{ textAlign: "center" }}>
                                            <b className='text-blue-500'>Yay! You have seen it all</b>
                                        </p>
                                    }
                                >
                                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 h-[500px] overflow-auto">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    <div className='flex items-center'>
                                                        <p>  Book&nbsp;ID</p>
                                                        <a href='javascript:void(0);' onClick={() => filterData(bookIdFilter, "bookId")}>
                                                            {bookIdFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                            </svg> :
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                </svg>}
                                                        </a>

                                                    </div>
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    <div className='flex items-center'>
                                                        <p>  Name</p>
                                                        <a href='javascript:void(0);' onClick={() => filterData(bookNameFilter, "bookName")}>
                                                            {bookNameFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                            </svg> :
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                </svg>}
                                                        </a>

                                                    </div>
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    <div className='flex items-center'>
                                                        <p>  Description</p>
                                                        <a href='javascript:void(0);' onClick={() => filterData(bookDescFilter, "bookDescription")}>
                                                            {bookDescFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                            </svg> :
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                </svg>}
                                                        </a>

                                                    </div>
                                                </th>

                                                <th scope="col" className="px-6 py-3">
                                                    VIDEO LINK
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {booksList.length !== 0 && booksList.map((val, index) => {
                                                return <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                    <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{val?.bookId}</td>
                                                    <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{val?.bookName[0]?.value}</td>
                                                    <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-pre-wrap dark:text-white">{val?.bookDescription[0]?.value}</td>


                                                    <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-pre-wrap dark:text-white">

                                                        <a href={val.videoLink} target="_blank">{val.videoLink}</a>
                                                    </td>

                                                    <td>
                                                        <span><a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => { navigate('/dashboard/books?id=' + val._id) }}>Edit</a> /&nbsp;
                                                            <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => { openDelete(val, val?._id, index) }}>Delete</a></span>
                                                    </td>
                                                </tr>
                                            })}
                                            {booksList.length === 0 && <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                <th scope="row" colSpan={6} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    No data found
                                                </th>
                                            </tr>}
                                        </tbody>
                                    </table>
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default BooksList