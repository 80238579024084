export const errorMessage = {
    medicinePage: {
        en_mediName: "Please enter medicine name in English",
        ar_mediName: "Please enter medicine name in Arabic",
        hb_mediName: "Please enter medicine name in Hebrew",

        en_smediName: "Please enter medicine short name in English",
        ar_smediName: "Please enter medicine short name in Arabic",
        hb_smediName: "Please enter medicine short name in Hebrew",

        en_mediDesc: "Please enter medicine description in English",
        ar_mediDesc: "Please enter medicine description in Arabic",
        hb_mediDesc: "Please enter medicine description in Hebrew",

        en_generalDose: "Please enter general dose in English",
        ar_generalDose: "Please enter general dose in Arabic",
        hb_generalDose: "Please enter general dose in Hebrew",

        en_maxiDose: "Please enter maximum dose in English",
        ar_maxiDose: "Please enter maximum dose in Arabic",
        hb_maxiDose: "Please enter maximum dose in Hebrew",

        en_method: "Please enter method in English",
        ar_method: "Please enter method dose in Arabic",
        hb_method: "Please enter method dose in Hebrew",

        doseperday: "Please select dose per day",

        routeOfAdminEN: "Please select route of administration in English",
        routeOfAdminAR: "Please select route of administration in Arabic",
        routeOfAdminHB: "Please select route of administration in Hebrew",

        en_tot: "Please enter time of treatment in English",
        ar_tot: "Please enter time of treatment in Arabic",
        hb_tot: "Please enter time of treatment in Hebrew",

        en_insForMedi: "Please enter instruction for medicine in English",
        ar_insForMedi: "Please enter instruction for medicine in Arabic",
        hb_insForMedi: "Please enter instruction for medicine in Hebrew",

        treatmentAndIns: "Please select treatment and instruction",

        en_generalExplain: "Please enter general explanation in English",
        ar_generalExplain: "Please enter general explanation in Arabic",
        hb_generalExplain: "Please enter general explanation in Hebrew",

        en_followup: "Please enter followups in English",
        ar_followup: "Please enter followups in Arabic",
        hb_followup: "Please enter followups in Hebrew",

        en_sidename: "Please enter side effect name in English",
        ar_sidename: "Please enter side effect name in Arabic",
        hb_sidename: "Please enter side effect name in Hebrew",

        en_sideDesc: "Please enter side effect description in English",
        ar_sideDesc: "Please enter side effect description in Arabic",
        hb_sideDesc: "Please enter side effect description in Hebrew",

        en_sideTreatmnet: "Please enter side effect treatment in English",
        ar_sideTreatmnet: "Please enter side effect treatment in Arabic",
        hb_sideTreatmnet: "Please enter side effect treatment in Hebrew",
    },
    diseaseType: {
        en_diseaseType: "Please enter disease type in English",
        ar_diseaseType: "Please enter disease type in Arabic",
        hb_diseaseType: "Please enter disease type in Hebrew",

        en_disease: "Please enter disease in English",
        ar_disease: "Please select diagnosis",
        hb_disease: "Please enter disease in Hebrew",

        en_diognosis: "Please enter diognosis in English",
        ar_diognosis: "Please enter diognosis in Arabic",
        hb_diognosis: "Please enter diognosis in Hebrew",

        year: "Please enter year",
        version: "Please enter version"
    },
    protocols: {
        en_pshortName: "Please enter protocol short name in English",
        ar_pshortName: "Please enter protocol short name in Arabic",
        hb_pshortName: "Please enter protocol short name in Hebrew",

        en_pName: "Please enter protocol name in English",
        ar_pName: "Please enter protocol name in Arabic",
        hb_pName: "Please enter protocol name in Hebrew",

        pYear: "Please enter protocol year",
        psYear: "Please enter  year",
        pVersion: "Please enter protocol version",

        pBelongDisease: "Please select belong to disease",
        en_pBelongDisease: "Please enter belong to disease in English",
        ar_pBelongDisease: "Please enter protocol name in Arabic",
        hb_pBelongDisease: "Please enter protocol name in Hebrew",

        en_pMediInclude: "Please enter medicines included in English",
        ar_pMediInclude: "Please enter medicines included in Arabic",
        hb_pMediInclude: "Please enter medicines included in Hebrew",

        tdd: "Please enter time duration days",
        dayStart: "Please enter day start",

        en_pcontinuationof: "Please enter continuation of in English",
        ar_pcontinuationof: "Please enter continuation of in Arabic",
        hb_pcontinuationof: "Please enter continuation of in Hebrew",

        en_condition: "Please enter condition in English",
        ar_condition: "Please enter condition in Arabic",
        hb_condition: "Please enter condition in Hebrew",

        en_protoCharacterstics: "Please enter protocol characterstics in English",
        ar_protoCharacterstics: "Please enter protocol characterstics in Arabic",
        hb_protoCharacterstics: "Please enter protocol characterstics in Hebrew",

        reqOrderNumber: "Please enter Required order number",
        en_reqPreparation: "Please enter Required preparation in English",
        ar_reqPreparation: "Please enter Required preparation in Arabic",
        hb_reqPreparation: "Please enter Required preparation in Hebrew",
        reqStartingday: "Please enter Required start day",

        nonreqOrderNumber: "Please enter Non Required order number",
        en_nonreqPreparation: "Please enter Non Required preparation in English",
        ar_nonreqPreparation: "Please enter Non Required preparation in Arabic",
        hb_nonreqPreparation: "Please enter Non Required preparation in Hebrew",
        nonreqStartingday: "Please enter Non Required start day",

        generalOrderNumber: "Please enter general order number",
        en_generalIns: "Please enter general instruction in English",
        ar_generalIns: "Please enter general instruction in Arabic",
        hb_generalIns: "Please enter general instruction in Hebrew",
        generalReport: "Please enter general report",

        explainOrderNumber: "Please enter general order number",
        en_explainlIns: "Please enter general instruction in English",
        ar_explainIns: "Please enter general instruction in Arabic",
        hb_explainIns: "Please enter general instruction in Hebrew",
        explainLink: "Please enter general report",
        lineNumber: "Please line number"
    },
    treatments: {
        medicine: "Please select medicine",
        medicineCondition: "Please select medicine condition",
        medicineThrough: "Please select medicine through",
        dayCount: "Please enter day count",
        dosePerTime: "Please enter dose per time",

        doseTimes: "Please select dose times",

        doseUnit: "Please enter dose unit in English",
        ar_doseUnit: "Please enter dose unit in Arabic",
        hb_doseUnit: "Please enter dose unit in Hebrew",

        dosePerDay: "Please enter dose per day",
        ar_dosePerDay: "Please enter dose per day in Arabic",
        hb_dosePerDay: "Please enter dose per day in Hebrew",

        treatmentDuration: "Please enter treatment duration in English",
        ar_treatmentDuration: "Please enter treatment duration in Arabic",
        hb_treatmentDuration: "Please enter treatment duration in Hebrew",


        en_mediIns: "Please enter medicine instruction in English",
        ar_mediIns: "Please enter medicine instruction in Arabic",
        hb_mediIns: "Please enter medicine instruction in Hebrew",

        routeOfAdminEN: "Please select route of administration in English",
        routeOfAdminAR: "Please select route of administration in Arabic",
        routeOfAdminHB: "Please select route of administration in Hebrew",



        en_explanation: "Please enter explanation in English",
        ar_explanation: "Please enter explanation in Arabic",
        hb_explanation: "Please enter explanation in Arabic",

        // en_mediCondition: "Please enter medicine condition in English",
        // ar_mediCondition: "Please enter medicine condition in Arabic",
        // hb_mediCondition: "Please enter medicine condition in Arabic",




    },

    profileError: {
        name: "Please enter your name",
        email: "Please enter your email",
        phone: "Please enter your mobile number",
        invalidPhone: "Please enter your valid mobile number",
        invalidEmail: "Please enter valid email",
        role: "Please select role"
    },

    giftError: {
        name: "Please enter the name",
        code: "Please enter the code",
        image: "Please select image",
        coins: "Please enter coins",
        ageGroup: "Please select age group"
    },
    StaffError: {
        name: "Please enter the name in English",
        ar_name: "Please enter the name in Arabic",
        hb_name: "Please enter the name in Hebrew",
        hospital: "Please select the hospital",
        ward: "Please select the ward",
        role: "Please enter the role",
        department: "Please enter department"
    },
    BooksError: {
        name: "Please enter the name in English",
        ar_name: "Please enter the name in Arabic",
        hb_name: "Please enter the name in Hebrew",
        desc: "Please enter the description in English",
        ar_desc: "Please enter the description in Arabic",
        hb_desc: "Please enter the description in Hebrew",
    },
    GameError: {
        name: "Please enter the name",
        desc: "Please enter the description",
        android: "Please enter the play store link",
        ios: "Please enter the apple store link",
    },
    UserError: {
        name: "Please enter the name in English",
        ar_name: "Please enter the name in Arabic",
        hb_name: "Please enter the name in Hebrew",
        phone: "Please enter phone number",
        invalidPhone: "Please enter invalid phone number",
        ward: "Please select the ward",
        hospital: "Please select hospital",
        age: "Please select age group"
    }


}

export const formInputClassName = "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 mb-4";

export const registerInput = "bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500";

export const challengeTypeOption = [{
    label: "IQ Tests",
    value: "IQ Tests"
}, {
    label: "Mathematical Skills",
    value: "Mathematical Skills"
}, {
    label: "Visual Pursuit",
    value: "Visual Pursuit"
}, {
    label: "Motorical Skills",
    value: "Motorical Skills"
}, {
    label: "English Skills",
    value: "English Skills"
}, {
    label: "Consistency Score",
    value: "Consistency Score"
}, {
    label: "General Awareness",
    value: "General Awareness"
}, {
    label: "Tech Abilities",
    value: "Tech Abilities"
}, {
    label: "Emotional Questions",
    value: "Emotional Questions"
}, {
    label: "Logical Reasoning",
    value: "Logical Reasoning"
}, {
    label: "Riding Ability Test",
    value: "Riding Ability Test"
}, {
    label: "Digital Markting",
    value: "Digital Markting"
}, {
    label: "Cook/Chef",
    value: "Cook/Chef"
}];



export const userTargetOption = [{
    label: "All",
    value: "All"
}, {
    label: "Patient",
    value: "Patient"
}, {
    label: "Doctor",
    value: "Doctor"
}];

export const questionTypeOption = [{
    label: "Text",
    value: "Text"
}, {
    label: "Image",
    value: "Image"
}, {
    label: "Video",
    value: "Video"
}, {
    label: "Audio",
    value: "Audio"
}];

export const userLevelOption = [{
    label: "Easy",
    value: "Easy"
}, {
    label: "Medium",
    value: "Medium"
}, {
    label: "Hard",
    value: "Hard"
}];

export const questionTimeOption = [{
    label: "30 Sec.",
    value: "30"
}, {
    label: "60 Sec.",
    value: "60"
}, {
    label: "90 Sec.",
    value: "90"
}, {
    label: "120 Sec.",
    value: "120"
}, {
    label: "150 Sec.",
    value: "150"
},
{
    label: "180 Sec.",
    value: "180"
},
{
    label: "210 Sec.",
    value: "210"
},
{
    label: "240 Sec.",
    value: "240"
},
{
    label: "270 Sec.",
    value: "270"
},
{
    label: "300 Sec.",
    value: "300"
},
{
    label: "330 Sec.",
    value: "330"
},
{
    label: "360 Sec.",
    value: "360"
},
{
    label: "390 Sec.",
    value: "390"
},
{
    label: "420 Sec.",
    value: "420"
},
{
    label: "450 Sec.",
    value: "450"
},
{
    label: "480 Sec.",
    value: "480"
},
{
    label: "510 Sec.",
    value: "510"
},
{
    label: "540 Sec.",
    value: "540"
},
{
    label: "570 Sec.",
    value: "570"
},
{
    label: "600 Sec.",
    value: "600"
}

];


export const answerTypeOption = [{
    label: "Image",
    value: "Image"
}, {
    label: "Text",
    value: "Text"
}];

export const answerCountOption = [{
    label: "2",
    value: "2"
}, {
    label: "3",
    value: "3"
}, {
    label: "4",
    value: "4"
}, {
    label: "5",
    value: "5"
}, {
    label: "6",
    value: "6"
}, {
    label: "7",
    value: "7"
}, {
    label: "8",
    value: "8"
}];


export const correctAnswerOption = [{
    label: "1",
    value: "1"
}, {
    label: "2",
    value: "2"
}, {
    label: "3",
    value: "3"
}, {
    label: "4",
    value: "4"
}, {
    label: "5",
    value: "5"
}, {
    label: "6",
    value: "6"
}, {
    label: "7",
    value: "7"
}, {
    label: "8",
    value: "8"
}];

export const buttonCSS = {
    blue: "text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2",
    green: "text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2",
    red: "text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2",
    addbtn: "text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 rounded-lg font-bold py-2 px-4 flex items-center"
}