import React, { useRef } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { checkFileExistOrNotAction, createBookAction, getBookDetailsAction, getSignedFileUrl, getStaffUpdatedAction } from '../../../Store/Action';
import { formInputClassName, errorMessage, buttonCSS } from '../../../util/contant'
import { DahboardMenu } from '../DahboardMenu';
import ErrorMessage from '../ErrorMessage';
import { TopHeader } from '../TopHeader';
import { getQuery, englishLangId, arabicLangId, hebrewLangId } from '../../../util/helper';
import { useState } from 'react';
import { useEffect } from 'react';
import { updateBookDetailsURL } from '../../../Api/ContantUrls';
import { toast } from 'react-toastify';
import PrevAndNextButtons from '../../../Elements/PrevAndNextButtons';
import { setLoading } from '../../../Store/Reducer';
import ConfirmPopup from '../../Popups/ConfirmPopup';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


const Books = () => {
    const availableLanguages = useSelector(state => state?.availableLanguages);
    const bookDetailStore = useSelector(state => state?.bookDetailStore);
    const editOptionData = useSelector(state => state?.editOptionData);
    const { BooksError } = errorMessage;
    const { register, handleSubmit, watch, formState: { errors }, getValues, reset, setError, setValue } = useForm();
    const dispatch = useDispatch();
    const [bookId, setBookId] = useState(getQuery('id'));
    const [selectedEditIndex, setSelectedEditIndex] = useState("");
    const [editedImage, setEditedImage] = useState("");
    const [arabicsub, setArabicSub] = useState("");
    const [hebrewsub, setHebrewSub] = useState("");
    const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
    const [showProgress, setShowProgress] = useState(false);
    const [progressPercent, setShowProgressPercent] = useState(0);
    const [pageRenderObj, setPageRenderObj] = useState({});
    const [hebrewSubUpdateInfo, SetHebrewSubUpdateInfo] = useState("");
    const [arabicSubUpdateInfo, SetArabicSubUpdateInfo] = useState("");


    useEffect(() => {
        if (bookId) {
            if (Object.keys(bookDetailStore).length === 0) {
                getBookDetails(bookId);
            } else {
                setSelectedEditIndex(editOptionData.indexOf(bookId))
                constructPageData();
            }
        }
    }, [bookDetailStore])

    const constructPageData = () => {
        const data = bookDetailStore;
        const reqObj = {};
        reqObj.name = data?.bookName[0]?.value;
        reqObj.ar_name = data?.bookName[1]?.value;
        reqObj.hb_name = data?.bookName[2]?.value;
        reqObj.desc = data?.bookDescription[0]?.value;
        reqObj.ar_desc = data?.bookDescription[1]?.value;
        reqObj.hb_desc = data?.bookDescription[2]?.value;
        reqObj.link = data?.videoLink;
        reqObj.subArabic = data?.subArabic;
        data?.subArabic && SetArabicSubUpdateInfo("Arabic Subtitle Added")
        reqObj.subHebrew = data?.subHebrew;
        data?.subHebrew && SetHebrewSubUpdateInfo("Hebrew Subtitle Added")
        reqObj.bookId = data?.bookId;
        reqObj.staffImage = {};
        setEditedImage(data?.videoLink)
        setPageRenderObj(reqObj)
        reset(reqObj)
    }

    const getBookDetails = async (id) => {
        dispatch(await getBookDetailsAction({ bookId: id }));
    }

    const submitHandler = async (data, isNew = null) => {
        if (editedImage === "" && data.link === "") {
            setError('link', { type: 'custom', message: 'Please enter video link or select file from your device' });
        } else {
            constructParams(data, isNew);
        }

    }

    const onError = (errors) => {
    }

    const constructReObj = (data) => {
        const reqObj = {};
        reqObj.bookName = [
            { language: englishLangId(availableLanguages.languageList), value: data.name },
            { language: arabicLangId(availableLanguages.languageList), value: data.ar_name },
            { language: hebrewLangId(availableLanguages.languageList), value: data.hb_name }
        ];
        reqObj.bookDescription = [
            { language: englishLangId(availableLanguages.languageList), value: data.desc },
            { language: arabicLangId(availableLanguages.languageList), value: data.ar_desc },
            { language: hebrewLangId(availableLanguages.languageList), value: data.hb_desc }
        ];
        reqObj.videoLink = editedImage ? editedImage : data.link;
        reqObj.subArabic = arabicsub ? arabicsub : data.subArabic;
        reqObj.subHebrew = hebrewsub ? hebrewsub : data.subHebrew;
        reqObj.bookId = data.bookId;
        return reqObj;
    }

    const constructParams = async (data, isNew = false) => {
        reset()
        const reqObj = constructReObj(data);

        if (bookId) {
            const apireqObj = {
                bookId,
                "dataToUpdate": reqObj
            }
            const response = await getStaffUpdatedAction(dispatch, updateBookDetailsURL, apireqObj);
            if (response?.status === 200 && response?.data) {
                toast.success("Book updated successfully")
                if (isNew) {
                    // window.location.href = '/dashboard/bookslist';
                } else {
                    // window.location.href = '/dashboard/books';
                }

            } else {
                toast.error("Book updated failed")
            }
        } else {
            dispatch(await createBookAction(reqObj, isNew));
        }

    }
    const saveAndExit = (data) => {
        submitHandler(data, true)
        console.log('data', data);

    }
    const saveAndNew = (data) => {
        submitHandler(data, false)

    }
    const discardChanges = () => {
        if (bookId) {
            constructPageData();
        } else {
            reset({});
        }
        // window.location.href = '/dashboard/bookslist';
    }

    const uploadFilesToAws = async (selectedFile, from = "") => {
        try {
            dispatch(setLoading(true));
            const signedRes = await getSignedFileUrl({});
            const { payload } = signedRes?.data;
            const { presignedUrl, fileUrl } = payload;
            dispatch(setLoading(false))
            if (presignedUrl) {
                setShowProgress(true);
                const blob = new Blob([new Uint8Array(10 * 1024 * 1024)]); // any Blob, including a File
                const uploadProgress = document.getElementById("upload-progress");
                //const uploadProgress = progressRef.current;
                const downloadProgress = document.getElementById("download-progress");

                const formData = new FormData();
                // Array.from(selectedFile).forEach((file, index) => formData.append(index.toString(), file));
                formData.append('file', selectedFile);

                const xhr = new XMLHttpRequest();
                const success = await new Promise((resolve) => {
                    xhr.upload.addEventListener("progress", (event) => {
                        if (event.lengthComputable) {
                            var percentComplete = event.loaded / event.total;
                            percentComplete = parseInt(percentComplete * 100);
                            setShowProgressPercent(percentComplete);
                            // uploadProgress.value = event.loaded / event.total;
                            // uploadProgress.value = event.loaded / event.total;
                            // progressRef.current.value = event.loaded / event.total;
                        }
                    });
                    xhr.addEventListener("progress", (event) => {
                        if (event.lengthComputable) {
                            downloadProgress.value = event.loaded / event.total;
                        }
                    });
                    xhr.addEventListener('error', () => {
                        toast.error("File upload failed")
                    });
                    xhr.addEventListener('abort', () => {
                        toast.error("File upload failed")
                    });
                    xhr.addEventListener("loadend", () => {
                        resolve(xhr.readyState === 4 && xhr.status === 200);
                    });
                    xhr.open("PUT", presignedUrl, true);
                    //xhr.setRequestHeader("Content-Type", "multipart/form-data");
                    //   xhr.setRequestHeader("X-File-Name", selectedFile.fileName);
                    xhr.setRequestHeader("Content-Type", selectedFile.type);
                    xhr.send(selectedFile);
                });
                if (from === 'sub1') {
                    setArabicSub(fileUrl)
                }
                if (from === 'sub2') {
                    setHebrewSub(fileUrl)
                }
                if (from === "") {
                    setEditedImage(fileUrl)
                }

                //  dispatch(setLoading(false))
                setShowProgress(false);
                setShowProgressPercent(0)

            }

        } catch (e) {
            setEditedImage("")
        }
    }
    const uploadGiftImage = async (e, from) => {
        const file = e.target.files[0];
        await uploadFilesToAws(file, from)
    }

    const setVideoLink = e => {
        e.preventDefault();
        setValue("staffImage", "")
        setValue(e.target.name, e.target.value);
        setEditedImage(e.target.value)
    }

    const closeConfirm = () => {
        setOpenConfirmPopup(false)
    }

    const closePreview = () => {
        setValue("staffImage", "")
        setValue("link", "")
        setEditedImage("");
        setOpenConfirmPopup(false)

    }

    const checkImage = async url => {
        try {
            if (bookId) {
                const reqObj = { filepath: url }

                const response = await dispatch(checkFileExistOrNotAction(reqObj, false));
                if (response) {
                    setEditedImage(response)
                } else {
                    setEditedImage(editedImage)
                    toast.error("Couldn't find file, Please upload again")
                }
            } else {
                setEditedImage(editedImage)
            }
        } catch (e) {
            setEditedImage("")
            toast.error("Couldn't find file, Please upload again")
        }
    }

    const checkIsModified = () => {
        if (JSON.stringify(pageRenderObj) === JSON.stringify(getValues())) {
            return false;
        } else {
            return true
        }
    }

    return (
        <React.Fragment>
            <TopHeader />
            <DahboardMenu />
            <div className="mt-10">
                <div className="
  max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
  xl:max-w-full xl:pl-40  xl:ml-40 xl:px-12 2xl:max-w-[1400px] mx-auto
 flex h-screen">
                    {openConfirmPopup && <ConfirmPopup modalIsOpen={openConfirmPopup} closeModal={closeConfirm} deleteData={closePreview} />}
                    <div className="flex w-full h-screen flex-col ">

                        <div className="relative h-screen mb-8">
                            <ErrorMessage errors={errors} />
                            <form className="w-full bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 " name='conversion' onSubmit={handleSubmit(submitHandler, onError)}>
                                {bookId &&
                                    <PrevAndNextButtons
                                        setRedirectId={setBookId}
                                        editOptionData={editOptionData}
                                        setSelectedEditIndex={setSelectedEditIndex}
                                        selectedEditIndex={selectedEditIndex}
                                        getData={getBookDetails}
                                        checkIsModified={checkIsModified}
                                        handleSubmit={handleSubmit}
                                        constructData={constructReObj}
                                        reset={reset}
                                        resetObj={{}}
                                        from="books" />}
                                <div className="mb-4">
                                    <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                        Name
                                    </label>
                                    {/* <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="medicineName" type="text" /> */}
                                    <input className={formInputClassName} autoComplete='off' placeholder='English' id="name" name="name" {...register("name", { required: BooksError.name })} />
                                    <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} name="ar_name" {...register("ar_name", { required: BooksError.ar_name })} />
                                    <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' name="hb_name" style={{ direction: "rtl", fontSize: "120%" }} {...register("hb_name", { required: BooksError.hb_name })} />

                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                        Description
                                    </label>
                                    {/* <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="medicineName" type="text" /> */}
                                    <input className={formInputClassName} autoComplete='off' placeholder='English' id="desc" name="desc" {...register("desc", { required: BooksError.desc })} />
                                    <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} name="ar_desc" {...register("ar_desc", { required: BooksError.ar_desc })} />
                                    <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' name="hb_desc" style={{ direction: "rtl", fontSize: "120%" }} {...register("hb_desc", { required: BooksError.hb_desc })} />

                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                        Video Link
                                    </label>
                                    {/* <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="medicineName" type="text" /> */}
                                    <input type='text' className={formInputClassName} autoComplete='off' placeholder='Video Link' id="link" name="link" {...register("link", { onChange: e => setVideoLink(e) })} />
                                </div>
                                <h2>(OR)</h2>
                                <div className="left-0 right-0 flex justify-between w-3/4 ml-80 p-2 bg-transparent text-white mb-4" style={{ marginLeft: "0px", width: "auto" }}>
                                    <div className="grid grid-cols-2 gap-2">
                                        <div className="mb-4 mt-3">
                                            <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                                Video
                                            </label>
                                            <input id="giftimage-file" accept="video/mp4,video/x-m4v,video/*" type="file" className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" {...register("staffImage")} onChange={e => uploadGiftImage(e)} />
                                        </div>


                                        {showProgress && <div className='text-center'>

                                            <div className='w-28' >
                                                <CircularProgressbarWithChildren className='h-28 w-28' value={progressPercent}>
                                                    <div style={{ fontSize: 12, marginTop: -5 }}>
                                                        <span className='text-blue-700'>Uploading....</span>
                                                        <br></br>
                                                        <strong className='text-blue-700 text-base'>{progressPercent + "%"}
                                                        </strong>
                                                    </div>
                                                </CircularProgressbarWithChildren>

                                            </div>

                                        </div>}
                                    </div>

                                    <div className="mb-4 mt-3">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Book Id
                                        </label>
                                        <input type='number' className={formInputClassName} {...register("bookId")} />
                                    </div>


                                </div>
                                <main className="mb-4 mt-3">
                                    <p className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                        Subtitle
                                    </p>
                                    <div className='flex gap-20'>
                                        <div>
                                            <label className='font-semibold text-gray-400' htmlFor="">Upload Arabic Subtitles here</label>
                                            <input id="giftimage-file" accept=".srt,.vtt" type="file" className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" {...register("subArabic")} onChange={e => uploadGiftImage(e, "sub1")} />
                                            <p>{arabicSubUpdateInfo}</p>

                                        </div>

                                        <div>
                                            <label className='font-semibold text-gray-400' htmlFor="">Upload Hebrew Subtitles here</label>
                                            <input id="giftimage-file" accept=".srt,.vtt" type="file" className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" {...register("subHebrew")} onChange={e => uploadGiftImage(e, "sub2")} />
                                            <p>{hebrewSubUpdateInfo}</p>

                                        </div>
                                    </div>
                                </main>

                                <div className="mb-4">
                                    <h3 className='text-2xl'>Preview</h3>
                                    <div className="relative w-1/2">

                                        {editedImage && <iframe width="420" height="315" onLoad={() => checkImage(editedImage)}
                                            src={editedImage} frameborder="0" allowfullscreen>
                                        </iframe>}
                                        {editedImage && <div className="absolute top-0 left-0 p-2">
                                            <i className="fas fa-icon-1 text-white">
                                                <button type="button" onClick={() => setOpenConfirmPopup(true)} className=" bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                    <span className="sr-only">Close menu</span>
                                                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </button>
                                            </i>
                                        </div>}
                                        {!editedImage && <div className='w-60 h-44 text-center align-middle border-solid border-2 border-black'>
                                            <p className='text-slate-300'>Your video will show here once the file is uploaded successfully</p>
                                        </div>}
                                    </div>

                                </div>
                                <div className="flex justify-between items-center mt-4">
                                    <a href='javascript:void(0);' className={buttonCSS.blue} onClick={handleSubmit((data) => saveAndExit(data), (err) => onError(err))}>Save & Exit</a>
                                    <a href='javascript:void(0);' className={buttonCSS.green} onClick={handleSubmit((data) => saveAndNew(data), (err) => onError(err))}>Save & New</a>
                                    <a href='javascript:void(0);' className={buttonCSS.red} onClick={() => { discardChanges() }}>Discard Changes</a>
                                </div>
                                {bookId &&
                                    <PrevAndNextButtons
                                        setRedirectId={setBookId}
                                        editOptionData={editOptionData}
                                        setSelectedEditIndex={setSelectedEditIndex}
                                        selectedEditIndex={selectedEditIndex}
                                        getData={getBookDetails}
                                        checkIsModified={checkIsModified}
                                        handleSubmit={handleSubmit}
                                        constructData={constructReObj}
                                        reset={reset}
                                        resetObj={{}}
                                        from="books" />}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Books